<template>
  <div class="categories-selector">
    <bottom-drawer
      v-if="isDrawer"
      v-model:visible="isPopupShown"
      :title="defaultLabel"
      :show-action="false"
      :show-footer="true"
      :submit-label="$t('all_confirm')"
      @submit="handleDrawerSubmit"
      @close="handleDrawerClose"
    >
      <template #trigger="{ open }">
        <nd-button class="max-w-85dvw !justify-start line-clamp-1" size="small" type="text" icon="niceday" @click="open">
          <h1>{{ buttonLabel }}</h1>
        </nd-button>
      </template>
      <nd-radio-group v-model="selectorValue" :options="radioOptions" />
    </bottom-drawer>

    <n-popover v-else v-model:show="isPopupShown" trigger="click" :show-arrow="false" placement="bottom-start" :z-index="zIndex.navbarPopover">
      <template #trigger>
        <nd-button :size="buttonSize" type="text" icon="niceday" :truncate-text="true" text-class="text-left">
          {{ buttonLabel }}
        </nd-button>
      </template>
      <template #default>
        <div class="list w-240px max-h-288px py-2 rounded text-base scrollbar">
          <template v-for="category in categoryUiList" :key="category.id">
            <locale-link :to="{ path: category.path }" @click="isPopupShown = false">
              <div class="py-3 px-5 hover:bg-gray-20 active:bg-gray-40 transition-colors">{{ category.name }}</div>
            </locale-link>
          </template>
          <scroll-mask />
        </div>
      </template>
    </n-popover>
  </div>
</template>

<script setup lang="ts">
import { NPopover } from 'naive-ui'

import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'

import BottomDrawer from '@/components/common/bottom-drawer.vue'
import NdRadioGroup from '@/components/form/nd-radio-group.vue'

import { zIndex } from '@/theme.config'

const { t } = useI18n()

const app = useAppStore()
const regionStore = useRegionStore()
const route = useRoute()
const router = useLocaleRouter()

const defaultLabel = computed(() => t('category_label'))

const isPopupShown = ref(false)

const isDrawer = computed(() => app.isMobile || app.isTablet)
const buttonSize = computed(() => (isDrawer.value ? 'small' : 'medium'))

const { regionId } = useRegion()

const region = computed(() => regionStore.regionInfo)
const isThemePage = computed(() => route.name?.toString().startsWith('regions-rid-themes-tid'))
const categories = computed(() => (isThemePage.value ? region.value?.theme : region.value?.category) || [])
const categoryRouteSubPath = computed(() => (isThemePage.value ? 'themes' : 'categories'))
const categoryUiList = computed(() =>
  categories.value.map(c => ({
    ...c,
    path: `/regions/${regionId.value}/${categoryRouteSubPath.value}/${c.id}`,
  }))
)
const categoryId = computed(() => Number((route.params.cid || route.params.tid) ?? -1))

const buttonLabel = computed(() => {
  const label = categories?.value?.find(c => c.id === categoryId.value)?.name || defaultLabel.value
  return label
})

// drawer data
const selectorValue = ref<number>(categoryId.value)
const radioOptions = computed(() => categories.value?.map(c => ({ name: c.name, id: c.id })) || [])
const handleDrawerClose = () => {
  selectorValue.value = categoryId.value
}
const handleDrawerSubmit = () => {
  if (selectorValue.value !== categoryId.value) {
    router.push(`/regions/${regionId.value}/${categoryRouteSubPath.value}/${selectorValue.value}`)
  }
}
</script>
