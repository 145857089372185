<template>
  <div class="mobile-search-button">
    <nd-button type="icon" :class="props.buttonClass" icon="search" @click="showSearchDrawer" />
    <n-drawer v-model:show="isActive" width="100%" placement="right" :z-index="zIndex.drawer" @after-enter="handleDrawerOpened">
      <n-drawer-content>
        <div class="actions py-2.5 px-3 flex items-center sticky top-0 bg-white z-10">
          <nd-button type="icon" icon="arrow-left" @click="isActive = false" />
          <nd-input
            ref="searchInputRef"
            v-model="keyword"
            class="mr-2"
            :placeholder="$t('all_search_placeholder')"
            size="small"
            clearable
            @submit="handleSearch"
          >
          </nd-input>
        </div>
        <search-hint @click-keyword="handleClickKeyword" />
      </n-drawer-content>
    </n-drawer>
  </div>
</template>

<script setup lang="ts">
import { NDrawer, NDrawerContent } from 'naive-ui'

import SearchHint from '@/components/search/search-hint.vue'
import NdInput from '@/components/form/nd-input.vue'

import { zIndex } from '@/theme.config'
import { GtmEvent } from '@/types/gtm'

const props = withDefaults(
  defineProps<{
    buttonClass?: string
  }>(),
  {
    buttonClass: '',
  }
)

const route = useRoute()
const router = useLocaleRouter()
const searchRecord = useSearchRecord()

const { regionId } = useRegion()

const searchInputRef = ref<Nullable<typeof NdInput>>(null)
const isActive = ref(false)
const keyword = ref((route.query.keyword as string) || '')

const showSearchDrawer = () => {
  isActive.value = true
}

const handleDrawerOpened = () => {
  // FIXME: 先用 setTimeout 解決點擊後無法 focus 的問題
  if (searchInputRef.value) {
    setTimeout(() => {
      searchInputRef.value!.focus()
    }, 100)
  }
}

const handleSearch = () => {
  if (searchInputRef.value) searchInputRef.value.blur()
  requestAnimationFrame(() => (isActive.value = false))
  searchRecord.add(keyword.value)

  useNuxtApp().$trackEvent({
    event: GtmEvent.SEARCH_QUERY_SENT,
    payload: {
      keyword: keyword.value,
    },
  })
  router.push({ path: `/regions/${regionId.value}/search`, query: { keyword: keyword.value } })
}

const handleClickKeyword = (selectedKeyword: string) => {
  keyword.value = selectedKeyword
  handleSearch()
}
</script>

<style lang="scss" scoped></style>
